/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'make_creator': {
    width: 19,
    height: 14,
    viewBox: '0 0 19 14',
    data: '<g _fill="none" fill-rule="evenodd"><g _fill="#154F4A" fill-rule="nonzero"><path pid="0" d="M4.833 8.5a3 3 0 110-6 3 3 0 010 6zm0-1a2 2 0 100-4 2 2 0 000 4z"/><path pid="1" d="M5.916 12.5H7.9a.46.46 0 00.46-.46v-.022C8.25 9.728 7.155 8.601 4.93 8.506c-2.226.095-3.32 1.222-3.428 3.512v.022c0 .254.205.46.46.46h1.982a.51.51 0 01.003 0H5.912a.51.51 0 01.004 0zm-.986 1H1.961a1.46 1.46 0 01-1.46-1.46l.002-.069C.627 9.326 1.97 7.814 4.412 7.544a.498.498 0 01.205-.044c.106 0 .21.002.313.006.103-.004.207-.006.313-.006.073 0 .143.016.205.044 2.442.27 3.785 1.782 3.91 4.427v.069a1.46 1.46 0 01-1.46 1.46H4.93z"/></g><g _fill="#154F4A" fill-rule="nonzero"><path pid="2" d="M13.833 6.5a3 3 0 110-6 3 3 0 010 6zm0-1a2 2 0 100-4 2 2 0 000 4z"/><path pid="3" d="M14.916 10.5H16.9a.46.46 0 00.46-.46v-.022c-.108-2.29-1.203-3.417-3.429-3.512-2.226.095-3.32 1.222-3.428 3.512v.022c0 .254.205.46.46.46h1.982a.51.51 0 01.003 0H14.912a.51.51 0 01.004 0zm-.986 1h-2.969a1.46 1.46 0 01-1.46-1.46l.002-.069c.124-2.645 1.467-4.157 3.909-4.427a.498.498 0 01.205-.044c.106 0 .21.002.313.006.103-.004.207-.006.313-.006.073 0 .143.016.205.044 2.442.27 3.785 1.782 3.91 4.427v.069a1.46 1.46 0 01-1.46 1.46H13.93z"/></g></g>'
  }
})
